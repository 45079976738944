import {ErrorHandler, Inject, Injectable, InjectionToken} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, tap} from 'rxjs';
import {Client as SentryClient} from '@sentry/types';

export const BACKEND_VERSION_HEADER_NAME = new InjectionToken('BACKEND_VERSION_HEADER_NAME');

@Injectable()
export class BackendVersionMonitorInterceptor implements HttpInterceptor {
  private lastBackendVersion: string | null = null;
  private errorReported = false;

  constructor(
    @Inject(BACKEND_VERSION_HEADER_NAME) private backendVersionHeaderName: string,
    private errorHandler: ErrorHandler,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap(response => {
        if (this.errorReported || !(response instanceof HttpResponse)) {
          return;
        }

        const backendVersion = response.headers.get(this.backendVersionHeaderName);

        if (!backendVersion) {
          return;
        }

        if (this.lastBackendVersion && backendVersion !== this.lastBackendVersion) {
          this.errorHandler.handleError(new Error('Multiple backend versions detected'));
          this.errorReported = true;

          return;
        }

        this.lastBackendVersion = backendVersion;
      }),
    );
  }
}

export function addBackendVersionToSentryXhrBreadcrumbs(client: SentryClient, backendVersionHeaderName: string) {
  client.on('beforeAddBreadcrumb', (breadcrumb, hint?) => {
    if (breadcrumb.category === 'xhr' && hint?.xhr instanceof XMLHttpRequest) {
      const backendVersion = hint.xhr.getResponseHeader(backendVersionHeaderName);

      if (backendVersion) {
        breadcrumb.data = {
          ...breadcrumb.data,
          backendVersion,
          cacheControl: hint.xhr.getResponseHeader('Cache-Control'),
        };
      }
    }

    return breadcrumb;
  });
}
