import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {A11yModule} from '@angular/cdk/a11y';

import {DialogOptions} from '@shared/modules/dialog/dialog.types';
import {AbstractDialogComponent} from '@shared/modules/dialog/abstract-dialog.component';
import {OverlayDirective} from '@shared/modules/overlay/overlay.directive';
import {TrapScrollDirective} from '@shared/directives/trap-scroll.directive';
import {ButtonComponent} from '@shared/components/button/button.component';

import {BubbleComponent} from '../../bubble/bubble.component';
import {BubbleActionsDirective} from '../../bubble/bubble-actions.directive';

@Component({
  selector: 'lcap-fullscreen-dialog',
  templateUrl: './fullscreen-dialog.component.html',
  styleUrls: ['./fullscreen-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    A11yModule,
    OverlayDirective,
    TrapScrollDirective,
    BubbleComponent,
    ButtonComponent,
    BubbleActionsDirective,
  ],
})
export class FullscreenDialogComponent extends AbstractDialogComponent implements OnInit {
  @ViewChild('closeConfirm') closeConfirm: OverlayDirective;

  buttonTitles: DialogOptions['closeConfirmationButtons'];

  override ngOnInit() {
    if (this.cancellable) {
      this.buttonTitles = {
        close: 'Close',
        cancel: 'Cancel',
        ...this.closeConfirmationButtons,
      };
    }

    super.ngOnInit();
  }

  handleCloseClick() {
    if (this.closeConfirmation) {
      this.closeConfirm.show();
    } else {
      this.close();
    }
  }
}
