import {Injectable} from '@angular/core';

import {AbstractDateFormatService} from '@shared/services/abstract-date-format.service';
import {I18nService} from '@shared/services/i18n.service';

@Injectable({
  providedIn: 'root',
})
export class DateFormatService extends AbstractDateFormatService {
  readonly longDate: string;
  readonly longDateTime: string;
  readonly mediumDateTime: string;

  constructor(private i18nService: I18nService) {
    super();

    this._inputDate = this.i18nService.localeFullDateFormat;
    this._shortInputDate = this.i18nService.localeFullDateFormat.replace(/y{4}/i, a => a.slice(0, 2));
    this._inputTime = this._time = this.i18nService.localeTimeFormat;

    if (this.isDayFirstFormat) {
      this._dayOfMonth = 'd MMM';
    }

    this.longDate = `${this.isDayFirstFormat ? 'd MMMM' : 'MMMM d'}, yyyy`;
    this.longDateTime = `${this.longDate} 'at' ${this._time}`;
    this.mediumDateTime = `${this._dayOfMonth} 'at' ${this._time}`;
  }

  override get isDayFirstFormat(): boolean {
    return /[d|M]/.exec(this.i18nService.localeFullDateFormat)?.[0] === 'd';
  }

  override get is24HoursFormat(): boolean {
    return false;
  }
}
